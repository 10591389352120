<template>
    <iframe :src="url" :style="iframeStyle" frameborder="0" allowfullscreen scrolling="no"></iframe>
  </template>
  
  <script>
  export default {
    name: 'IframeComponent',
    props: {
      url: {
        type: String,
        required: true
      },
      iframeStyle: {
      type: Object,
      default: () => ({})
    }
    }
  }
  </script>
  
  <style scoped>
  iframe {
    width: 100%;
    
    overflow: hidden; 
    border-radius: 20px; 
  
  
  }
  </style>
  