<template>
   <div class="charts-container">



<image-layout></image-layout>
    
    
    <!-- <img src="../images/tit1.png" alt="Right Large Image" class="tradingview-chart-container1"/>
     -->


     <!-- <div class="tradingview-chart-container1">
  <div class="tradingview-chart-container1">
 
    <img src="../images/logo.png" alt="Right Large Image" class="container-image2"/>

    <div class="tradingview-chart-container2">
<h2 class="c1">주식투자로 언제까지
</h2>
<h2 class="c1">손실만 보고 
</h2>
<h2 class="c1">계실건가요? 
</h2>
<h2 class="c1">투자도 이제는
</h2>
<h2 class="c1">AI 기술을 활용해야
</h2>
<h2 class="c1">위험 회피, 수익의
</h2>
<h2 class="c1">초 극대화가 가능
</h2>
<h2 class="c1">합니다!
</h2>
    </div>

  </div>
</div> -->










    <div class="tradingview-chart-container">
     
    <div class="tradingview-widget-container"  style="height: 100%; width: 100%;">
      
     <!-- k线图 -->
     <KvS1></KvS1>
    
       
      
    </div></div>

    <div class="tradingview-chart-container">
    
    <div class="tradingview-widget-container"  style="height: 100%; width: 100%;">
      
    
      
      <KvS2></KvS2>
    </div></div>


    <div class="tradingview-chart-container">
    
    <div class="tradingview-widget-container"  style="height: 100%; width: 100%;">
      
  
      <KvS3></KvS3>
    </div></div>

    <div class="tradingview-chart-container">
    
    <div class="tradingview-widget-container"  style="height: 100%; width: 100%;">
      
      <KvS4></KvS4>
    </div></div>


    <div class="tradingview-chart-container">
    
    <div class="tradingview-widget-container"  style="height: 100%; width: 100%;">
      
      <KvS5></KvS5>
    </div></div>





</div>
  </template>
  
  <script>

  import ImageLayout from './ImageLayout.vue';

  import KvS1 from './KvS1.vue';
  import KvS2 from './KvS2.vue';
  import KvS3 from './KvS3.vue';
  import KvS4 from './KvS4.vue';
  import KvS5 from './KvS5.vue';
 
  export default {
    components: {
      ImageLayout,
      KvS1,
      KvS2,
      KvS3,
      KvS4,
      KvS5,
      
    },




  }
  </script>
  
  <style scoped>









.container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tradingview-chart-container1,
.tradingview-chart-container2 {
  position: relative;
  width: 300px;    
  height: 300px;   
  border-radius: 10px;
  border: 2px solid #ffffff; 
  background-color: #006eff; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
  margin-bottom: 10px; /* 为第一个容器添加底部边距 */
}

.container-image2 {
  width: 100%; /* 让图片填充容器的宽度 */
  border-top-left-radius: 8px; /* 顶部圆角 */
  border-top-right-radius: 8px; /* 顶部圆角 */
}







.charts-container {
  display: flex;
  justify-content: space-around; 
  align-items: center; 

}

.tradingview-chart-container {
/* margin: 10px; */
  position: relative;
   width: 300px;     
  height: 300px;  
  padding:  0;
border-radius: 10px;
  margin: 0 0;  
 
  background-color: #ffffff; 
 
}








@media (max-width: 768px) {
  .charts-container {
    /* flex-direction: row;  */
    flex-direction: column; 
    flex-wrap: wrap; 
  }
  .tradingview-chart-container {
    
    width: 100%;
    height: 390px;  
  }
  .logo-container {
    top: 56%;
   
  }





}





  </style>
  