<template>
    <div class="image-layout">
      <img src="../images/logo1.png" alt="Image 1" class="image1"/>
      <div class="image-with-text">
        <p class="text-over-image">
          주식투자로 언제까지
          <br>손실만 보고
          <br>계실건가요?
          <br>투자도 이제는
          <br>AI 기술을 활용해야
          <br>위험 회피, 수익의
        <br>초 극대화가 가능
        <br>합니다! 
        </p>
        <img src="../images/home2.png" alt="Image 2" class="image2"/>
      </div>
      <OtherComponent />
    </div>
  </template>
  
  
  
  <style scoped>





  .image-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
    overflow: hidden;
    margin:  0;
    
  }
  
  .image1 {
    width: 200px;
    height: auto;
    /* margin: 10px 0; */
    margin: 0;
    display: block
  }
  .image2 {
    width: auto;
    height: 100%;
    /* margin: 10px 0; */
    margin: 0;
    display: block
  }
  
  .image-with-text {
    position: relative;
    
  }
  
  .text-over-image {
    position: absolute;
   font-size: 5%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* 或者您选择的任何颜色 */
    background-color: rgba(255, 255, 255, 0); /* 半透明背景 */
    /* padding: 1px; */
    text-align: center;
  }
  
 
  @media (max-width: 768px) {
    .image-layout {
      flex-direction: row;
      justify-content: center;
      border-radius: 30px;
      margin: 10px 0;
    margin-left:0;
    }
  
    /* .image-with-text .text-over-image {
      transform: none;
    left: auto;
    top: auto;
      background-color: transparent;
    } */
    .image1 {
    width: 100px;
    height: 100%;
    /* margin: 10px 0; */
  }
    .image2 {
    width: 300px;
    height: 200px;
    /* margin: 10px 0; */
    border-radius: 20px;
  }
  .text-over-image{
    font-size: 15px;
    top: 40%;
  }
  }
  </style>
  