import { createRouter, createWebHashHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';


// 定义路由数组
const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/PageData1',
    component: () => import('../components/PageData1.vue'), 
  },
  {
    path: '/PageData2',
    component: () => import('../components/PageData2.vue'), 
  },
  {
    path: '/PageData3',
    component: () => import('../components/PageData3.vue'), 
  },
  {
    path: '/PageData4',
    component: () => import('../components/PageData4.vue'), 
  },
  {
    path: '/PageData5',
    component: () => import('../components/PageData5.vue'), 
  },
  {
    path: '/PageData6',
    component: () => import('../components/PageData6.vue'), 
  },
  {
    path: '/PageData7',
    component: () => import('../components/PageData7.vue'), 
  },
  {
    path: '/PageData8',
    component: () => import('../components/PageData8.vue'), 
  },
  {
    path: '/PageData9',
    component: () => import('../components/PageData9.vue'), 
  },
  {
    path: '/PageData10',
    component: () => import('../components/PageData10.vue'), 
  },
  {
    path: '/PageData11',
    component: () => import('../components/PageData11.vue'), 
  },
  {
    path: '/PageData12',
    component: () => import('../components/PageData12.vue'), 
  },
  {
    path: '/PageData13',
    component: () => import('../components/PageData13.vue'), 
  },
  {
    path: '/PageData14',
    component: () => import('../components/PageData14.vue'), 
  },
  {
    path: '/PageData15',
    component: () => import('../components/PageData15.vue'), 
  },
  {
    path: '/PageData16',
    component: () => import('../components/PageData16.vue'), 
  },
  
  {
    path: '/PageData17',
    component: () => import('../components/PageData17.vue'), 
  },
  {
    path: '/HoesaSogae',
    component: () => import('../components/HoesaSogae.vue'), 
  },

  
 
];

// 创建并配置路由器
const router = createRouter({
  history: createWebHashHistory(),
  routes, // 使用路由数组
  scrollBehavior(to, from, savedPosition) {
    // 如果存在savedPosition，则滚动到保存的位置
    if (savedPosition) {
      return savedPosition;
    }
    // 否则滚动到页面顶部
    return { top: 0 };
  },
});
// 全局后置守卫
router.afterEach(() => {
  function refreshIframe() {
    const iframe = document.getElementById('responsive-iframe');
    if (!iframe) return;

    const reloadIframe = (delay = 0) => {
      setTimeout(() => {
        let iframeSrc = iframe.getAttribute('src').split('?')[0];
        iframeSrc += `?t=${new Date().getTime()}`;
        iframe.src = iframeSrc;
      }, delay);
    };

  
    reloadIframe();
    setTimeout(() => {
      window.location.reload();
    }, ); 
    setTimeout(() => {
      window.location.reload();
    }, 2000); 
  }

  refreshIframe();
});
export default router;
