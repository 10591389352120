<template>
  <br>
  <br>
    <div class="home-page">
   
<DataS11></DataS11>

 
<br>
<br>


<div class="image-container">
    <div class="left-images">


      <img src="../images/person.png" alt="Left Top Image" />
     
 <div class="text-module">
        <h2 class="title">최남호교수님 – 이상파트너스 수석 애널리스트</h2>
        <p class="description">AI 스마트 트레이딩 전문가, '올해의 투자매니저' 3년 연속 수상 이상파트너스에서 수석 애널리스트로 일하고 있습니다.
</p>

<p class="description">전 세계 여러 은행, 자산관리회사, 소셜 싱크탱크에서 컨설턴트와 선임 행정직을 역임하며, 일련의 금융계측과 분석기법을 개발하고 주식시장 운영방면에서 독특한 감각과 전략을 가지고 있습니다.
</p>
<p class="description">교수님의 수상 경력 또한 금융 투자 분야에서 탁월한 성과를 보여 여러 분야에서 상을 받았습니다. 투자 분야에서 뛰어난 능력을 인정받아 한국금융협회로부터 '올해의 투자 매니저'상을 3년 연속 수상했습니다. 또한 한국증권거래소로부터 '베스트 포트폴리오 매니저'상과 한국금융감독기관으로부터 '금융혁신상'을 여러 차례 수상하며 업계에서 인정과 찬사를 받았습니다.
</p>
<p class="description">최민호 교수는 전통 금융투자 분야에서 우수한 성적을 거둔 것 외에도 AI 스마트 트레이딩에 대한 오랜 연구와 운용을 해왔으며,
이상파트너스에 합류하신이후 이 회사와 합작하여 AI 스마트 거래 시스템 개발에 적극적으로 연구하여 인공지능 기술과 전통적인 투자 전략을 결합함을 통해서 그는 투자 수익을 높이고 고객에게 더 나은 투자 서비스를 제공하기 위해 오늘도 열정을 다하고 있다.
</p>

      </div>





    </div>
    <div class="right-image">
      <img src="../images/ai1.png" alt="Right Large Image" />
    </div>
  </div>











    </div>
  </template>
  
  <style scoped>
.image-container {
  justify-content: center; /* 水平居中 */
  
  display: flex;
  border-radius: 10px;
  /* text-align: center; */
}

.left-images {
  display: flex;
  flex-direction: column; /* 垂直排列图片 */
  width: 30%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgb(30, 173, 240);

  margin: 0 50px;
}
.right-images {
  display: flex;
  flex-direction: column; 
  width: 30%;
  border-radius: 10px;
  margin: 0 50px;
}

.left-images img {
  width: 100%; 
  height: auto;
  margin-bottom:0px; 
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);  */

  margin: 20px 0;
}

.right-image img {
  
  width: 97%; 
  height: auto;
  /* margin: 0 80px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
}



@media (max-width: 768px) {
  .image-container {
    flex-direction: column; 
    justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px;
  }

  .left-images {
    width: 80%; 
    justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px;

  }
  .right-image{
    width: 80%; 
    justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px;
  }
}


.left-section {
  flex: 1;
  text-align: center; /* 居中文字 */
}

.text-module {
  margin-bottom: 20px; /* 与图片的间距 */
  width: 80%;
  color: #fff;
  margin: 20px auto;
}

.title {
  color: rgb(33, 69, 230); /* 深蓝色标题 */
  margin-bottom: 10px; /* 与描述的间距 */
  background-color: #fff;
  border-radius: 20px;
  padding:0 10px;
  font-size: 20px;
}
</style>

  
  
  <script>


import DataS11 from '../components/DataS11.vue';




export default {
  name: 'App',
  components: {
    DataS11,
    
},
}

</script>