<template>
    <!-- <div id="app" :class="{ 'dark-theme': darkTheme }"> -->
      <div>
      <!-- 顶部任务栏 -->
      <nav class="top-bar">
  
         <router-link to="/">
        <img src="../images/logo1.png" alt="Logo" class="logoPH">
      </router-link>
       
       
        <!-- <TranslateButton /> -->
        
       
  <button class="menu-button" @click="toggleNav">
  
    <!-- 当 isNavOpen 为 true 时显示这个图标 -->
    <ion-icon v-if="isNavOpen" name="close-outline" class="fff"></ion-icon>
    
    <!-- 当 isNavOpen 为 false 时显示这个图标 -->
    <ion-icon v-else name="menu-outline" class="bbb"></ion-icon>
  
   
  </button>
  
  
  
      </nav>
  
       <!-- 弹出式侧边导航栏 -->
       <!-- <div class="side-nav" :class="{ 'open': isNavOpen }">
        <div class="nav-links-container">
        
    
          
          <h2 class="nav-link"><ion-icon name="cube-outline"></ion-icon> MENU</h2>
  
          <router-link to="/AboutMe" class="nav-link">회사소개</router-link>
        <router-link to="/AboutPj" class="nav-link">국내증시</router-link>
        <router-link to="/AboutPm" class="nav-link">해외증시</router-link>
        <router-link to="/AboutChat" class="nav-link">시  황</router-link>
        <router-link to="/AboutChat" class="nav-link">종목분석</router-link>
        <router-link to="/AboutChat" class="nav-link">시장지표</router-link>
        <router-link to="/AboutChat" class="nav-link">투자정보</router-link>
        <router-link to="/AboutChat" class="nav-link">국내외 뉴스</router-link>
        <router-link to="/AboutChat" class="nav-link">공  지</router-link>
  
  
        <button class="nav-title1" @click="toggleTheme">
    <ion-icon name="invert-mode-outline" class="dark1"></ion-icon>
        </button>
  
      </div>
     
    </div>    -->
    <div class="side-nav" :class="{ 'open': isNavOpen }">
    <div class="nav-links-container">


      <h3 class="nav-link1" @click="toggleSubMenu('menu')">
        <ion-icon name="filter-outline"></ion-icon> 회사소개
      </h3>
      <div v-if="subMenuStates.menu" class="submenu">
        <router-link to="/PageData1" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>회사소개</router-link>
        <router-link to="/PageData2" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>투자철학</router-link>
        <!-- <router-link to="/HoesaSogae" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>회사 소개</router-link>
       -->
      </div>
   
      <h3 class="nav-link1" @click="toggleSubMenu('menu1')">
        <ion-icon name="filter-outline"></ion-icon> 국내증시

      </h3>
      <div v-if="subMenuStates.menu1" class="submenu">
        <router-link to="/PageData3" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>국내증시</router-link>
      </div>
   
      <h3 class="nav-link1" @click="toggleSubMenu('menu2')">
        <ion-icon name="filter-outline"></ion-icon> 해외증시

      </h3>
      <div v-if="subMenuStates.menu2" class="submenu">
        <router-link to="/PageData4" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>해외증시</router-link>
      </div>
   

      <h3 class="nav-link1" @click="toggleSubMenu('menu3')">
        <ion-icon name="filter-outline"></ion-icon> 시  황

      </h3>
      <div v-if="subMenuStates.menu3" class="submenu">
        <router-link to="/PageData5" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>시  황</router-link>
      </div>
   
      <h3 class="nav-link1" @click="toggleSubMenu('menu4')">
        <ion-icon name="filter-outline"></ion-icon> 종목분석

      </h3>
      <div v-if="subMenuStates.menu4" class="submenu">
        <router-link to="/PageData6" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>종목랭킹
</router-link>
        <router-link to="/PageData7" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>상승종목
</router-link>
<router-link to="/PageData8" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>상승테마

</router-link>
        <router-link to="/PageData9" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>상승업종
</router-link>
      </div>
   




      <h3 class="nav-link1" @click="toggleSubMenu('menu5')">
        <ion-icon name="filter-outline"></ion-icon> 시장지표

      </h3>
      <div v-if="subMenuStates.menu5" class="submenu">
        <router-link to="/PageData10" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>환  율
</router-link>
        <router-link to="/PageData11" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>상   품
</router-link>
<router-link to="/PageData12" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>금리/채권

</router-link>
      </div>
   
      

      <h3 class="nav-link1" @click="toggleSubMenu('menu6')">
        <ion-icon name="filter-outline"></ion-icon> 투자정보

      </h3>
      <div v-if="subMenuStates.menu6" class="submenu">
        <router-link to="/PageData13" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>분석리포터
</router-link>
        <router-link to="/PageData14" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>종목리포터
</router-link>
<router-link to="/PageData15" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>이슈분석
</router-link>
      
      </div>
   




      <h3 class="nav-link1" @click="toggleSubMenu('menu7')">
        <ion-icon name="filter-outline"></ion-icon> 국내외 뉴스

      </h3>
      <div v-if="subMenuStates.menu7" class="submenu">
        <router-link to="/PageData16" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>국내 뉴스
</router-link>
        <router-link to="/PageData17" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>국제 뉴스
</router-link>

      </div>
   

<!-- 
       <h3 class="nav-link1" @click="toggleSubMenu('menu8')">
        <ion-icon name="filter-outline"></ion-icon> 공지


      </h3>
      <div v-if="subMenuStates.menu8" class="submenu">
        <router-link to="/PageData18" class="nav-link" @click="closeNav"><ion-icon name="chevron-forward-outline"></ion-icon>공지

</router-link>
 
      </div>
     -->






    </div>
  </div>











     </div>
  
  
  
  
  
  
  
      <div class="content">
        
        <router-view></router-view> 
      </div>
  
  
  
      <br>
  <br>
  <br>
  <br>
    <!-- <hr style="border: 1px solid #00d3b7; margin: 0 20px; " >
  <br> -->
 
  
      <footer class="bottom-bar">
       
      <p class="p3">  IDEAL PARTNERS 와 콘텐츠 제공업체는 제공된 정보에 의한 투자 결과에 법적인 책임을 지지 않습니다. 게시된 정보는 무단으로 배포할 수 없습니다
       </p>
      <br>
      
      
      
      </footer>
    <!-- </div> -->
  
    <div>
      
      <BackToTopButton />
    </div>
    
  </template>


<script>
import BackToTopButton from '../components/BackToTopButton.vue';
// import TranslateButton from '../components/TranslateButton.vue';
// import { mapState } from 'vuex';


export default {
  name: 'App',
  components: {
    BackToTopButton,
    // TranslateButton,
}, 

// computed: {
//     ...mapState(['isLoggedIn', 'userId']),
//   },

//任务栏的方法
  data() {
    return {

      subMenuStates: {
        menu: false, 
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
        menu6: false,
        menu7: false,
        menu8: false,

      },


      isNavOpen: false,
      
      



    };
  },
  methods: {
   
    toggleSubMenu(menuItem) {
      this.subMenuStates[menuItem] = !this.subMenuStates[menuItem];
    },
    closeNav() {
    this.isNavOpen = false;
    document.body.classList.remove('no-scroll');
  },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen; 
      if (this.isNavOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  
    }, 


 
   

  }
};



</script>

<style scoped>








.logoPH {
  /* position: absolute;
  top: 10px; 
  left: 40%;  */
  /* max-width: 30%; 
  height: 20%;  */
  display: block; 
  max-width: 150px;  
  max-height: 50px;  
  height: auto;   
  left: 3%;
  position: absolute;
  top: 1px;
}



.fff{
  color: #000000;
}
.bbb{
  color: #fff;
}
</style>


