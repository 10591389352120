<template>
   
     <nav class="top-bar1">
    
     <div class="logo-container">
        <router-link to="/">
          <img src="../images/logo1.png" alt="Logo" class="logoPC">
        </router-link>
      </div> 


<!-- 导航链接 -->
<div class="nav-links1">
 



  <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">회사소개</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData1" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>회사소개</router-link>
        <router-link to="/PageData2" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>투자철학</router-link>
        <!-- <router-link to="/HoesaSogae" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>회사소개</router-link>
       -->
      </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">국내증시</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData3" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>국내증시</router-link>
       </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">해외증시</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData4" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>해외증시</router-link>
       </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">시  황</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData5" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>시  황</router-link>
       </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">종목분석</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData6" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>종목랭킹</router-link>
        <router-link to="/PageData7" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>상승종목</router-link>
        <router-link to="/PageData8" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>상승테마</router-link>
        <router-link to="/PageData9" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>상승업종</router-link>
        
      </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">시장지표</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData10" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>환  율</router-link>
        <router-link to="/PageData11" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>상   품
</router-link>
        <router-link to="/PageData12" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>금리/채권</router-link>
        
      </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">투자정보</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData13" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>분석리포터</router-link>
        <router-link to="/PageData14" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>종목리포터</router-link>
        <router-link to="/PageData15" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>이슈분석</router-link>
        
      </div>
      </div>

      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">국내외 뉴스</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData16" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>국내 뉴스</router-link>
        <router-link to="/PageData17" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>국제 뉴스</router-link>
       </div>
      </div>

      <!-- <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-linkpc">공지</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    <router-link to="/PageData18" class="nav-linkpc1"><ion-icon name="chevron-forward-outline"></ion-icon>공지사항</router-link>
       </div>
      </div>

   
      <div class="nav-item" @mouseover="setActiveMenu('about')" @mouseleave="clearActiveMenu">
  <h3 class="nav-login">LOGIN</h3>
  <div v-if="activeMenu === 'about'" class="submenu1">
    </div>
 
      </div> -->


     
   





    </div>
    
    </nav> 
  











    <div class="content">
      <router-view></router-view> 
    </div>
  
  
  
      <br>
 
    <!-- <hr style="border: 1px solid #00d3b7; margin: 0 20px; " >
  <br> -->
  
  
      <footer class="bottom-bar">
        <p class="p3">  IDEAL PARTNERS 와 콘텐츠 제공업체는 제공된 정보에 의한 투자 결과에 법적인 책임을 지지 않습니다. 게시된 정보는 무단으로 배포할 수 없습니다
       </p>
    
    
      
      </footer>
 
  
    <div>
      
      <BackToTopButton />
    </div>
    
  </template>


<script>
import BackToTopButton from '../components/BackToTopButton.vue';



export default {
  name: 'App',
  components: {
    BackToTopButton,
   
},

//任务栏的方法
  data() {
    return {
      isNavOpen: false,
      activeMenu: null,

    };
  },
  methods: {
    clearActiveMenu() {
    this.activeMenu = null;
  },
    setActiveMenu(menuItem) {
    this.activeMenu = menuItem;
  },

  


 
  }
};



</script>






<style scoped>


.top-bar1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  background-color: #ffffff00; /* 根据您的设计调整背景颜色 */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.logo-container {
  flex: 1;
}



.nav-links1 {
  /* display: flex;
  flex: 5;
  justify-content: flex-start;
   */

  display: flex;
  justify-content: space-between; /* 或者使用 flex-end 来使所有子元素靠右对齐 */
  align-items: center;
}


.translate-button-container1 {
  flex: 3;
  display: flex;
  justify-content: flex-start;
}


.logoPC {
  /* position: absolute;
  top: 10px; 
  left: 40%;  */
  /* max-width: 30%; 
  height: 20%;  */
  display: block; 
  max-width: 150px;  
  max-height: 60px;  
  height: auto;   
  left: 3%;
  position: absolute;
  top: 5px;
}






.nav-item {
  position: relative; /* 设置相对定位，以便子菜单相对于此定位 */
  
}

.submenu1 {
  position: absolute;
  top: 100%; /* 将子菜单放在导航项的正下方 */
  left: 0;
  /* width: 100%; */
  background-color: #fff; 
  border: 1px solid #ccc; 
  display: none; 
  z-index: 2000;
  border-radius: 10px;
}


.nav-item:hover .submenu1,
.submenu1:hover {
  display: block;
  
}


.nav-login {
 

color: #f78316;
padding: 10px 20px; /* 增加一些内边距以改善点击区域 */
text-decoration: none;
width: 100%; /* 链接宽度占满父容器 */
box-sizing: border-box;
margin: 0; /* 移除外边距 */
transition: color 0.3s ease;
cursor: pointer;
/* border-bottom: 1px solid #cccccc;  */
padding: 10px; /* 保持原有的内边距 */

border-radius: 10px;
 transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */

}


.nav-login:hover {
background-color: #ffffff; /* 悬停时的背景颜色变为浅灰色 */
font-size: larger;
transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */
}








 

 /* 导航链接样式 */
 .nav-linkpc {

  
 
  display: block;
  color: #ffffff;
  padding: 10px 20px; /* 增加一些内边距以改善点击区域 */
  text-decoration: none;
  width: 100%; /* 链接宽度占满父容器 */
  box-sizing: border-box;
  margin: 0; /* 移除外边距 */
  transition: color 0.3s ease;
  cursor: pointer;
  /* border-bottom: 1px solid #cccccc;  */
  padding: 10px; /* 保持原有的内边距 */

  border-radius: 10px;
   transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
  transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */
  
 }


 .nav-linkpc:hover {
  background-color: #ffffff; /* 悬停时的背景颜色变为浅灰色 */
  color: #0e5ef1;
  font-size: larger;
  transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
  transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */
}


.nav-linkpc1 {

  
 
display: block;
color: #000000;
padding: 10px 20px; /* 增加一些内边距以改善点击区域 */
/* text-decoration: none; */
width: 100%;
box-sizing: border-box;
margin: 0; 
transition: color 0.3s ease;
cursor: pointer;
/* border-bottom: 1px solid #cccccc;  */
padding: 10px; /* 保持原有的内边距 */


 transition: background-color 0.3s ease; 
transition: font-size 0.3s ease; 

}


.nav-linkpc1:hover {
background-color: #dbdbdba1; /* 悬停时的背景颜色变为浅灰色 */
font-size: larger;
transition: background-color 0.3s ease; /* 添加平滑的过渡效果 */
transition: font-size 0.3s ease; /* 添加平滑的过渡效果 */
border-radius: 5px;
}




</style>
